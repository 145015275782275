import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { LandSellerValidationSchema } from '../../components/common/forms/constraints/ValidatonSchemas'
import { FormContainer, setInputProps } from '../../components/common/forms/Form'
import { setActiveModal } from '../../data/modal';

export default function VendeTuTerreno () {
    const dispatch = useDispatch();
    const handleSubmit = () => {
        alert()
    }

    const initialValues = {
        names: "",
        email: "",
        telephone: "",
        region: "",
        district: "",
        address: "",
        file:"",
        message: "",
        terms: false
    }

    useEffect(()=>{
        document.querySelector("html").scrollTop = 0
        document.querySelector(".Navbar").classList.remove("inactiveDesktop")
        document.querySelector(".Navbar").classList.add("inactive")

        let links = document.querySelectorAll(".proyectos .project")
        links.forEach((e)=>{
            e.classList.remove("active")
        })
        document.querySelector("#link-vende-tu-terreno").classList.add("active")
    }, [])

    return (
        <main className="Main-contacto vendetuterreno">
            <section className="section-header">
                <span className="title-1 white text-center d-block">VENDE</span>
                <h1 className="title title-2 green bold text-center">TU TERRENO</h1>
                <span className="paragraph white text-center d-block">
                    Somos una empresa confiable, con altos
                    estándares de calidad. Tenemos proyectos en las
                    mejores zonas de Lima, entregando plusvalía y
                    proyección, que nos asegura una rentabilidad,
                                                                    por sobre el mercado.
                                                                </span>
                                                                <span className="paragraph white text-center d-block">
                                                                    Propiedades en avenidas principales, de
                    preferencia esquinas. Área mínima 800 m²
                    (Con la posibilidad de acumular varios lotes).
                </span>
                <span className="paragraph white text-center d-block">
                    Envía el detalle del predio con tus datos y
                    desarrollaremos la mejor alternativa de
                    compra-venta que beneficie a ambas partes.
                </span>
            </section>
            <FormContainer initialValues={initialValues} onSubmit={handleSubmit} validationSchema={LandSellerValidationSchema}>
            {form =>{const {handleSubmit} = form;
            console.log(form)
            return(
                <form className="formulario" onSubmit={handleSubmit}>
                    <h2 className="title-form bold">Contáctanos</h2>
                    <div className="input"><input placeholder="Nombre y apellidos*" {...setInputProps("names", "", form)}></input></div>
                    <div className="input"><input placeholder="Correo electrónico*" {...setInputProps("email", "", form)}></input></div>
                    <div className="input"><input placeholder="Teléfono / Celular*"  {...setInputProps("telephone", "", form)}></input></div>
                    <div className="input">
                        <select placeholder="Escoge un proyecto"  {...setInputProps("region", "", form)}>
                            <option>Región</option>
                            <option>Lima</option>
                        </select></div>
                    <div className="input"><input placeholder="Distrito*"  {...setInputProps("district", "", form)}></input></div>
                    <div className="input"><input placeholder="Dirección de terreno*" {...setInputProps("address", "", form)}></input></div>
                    <div className="input">
                        <input type="file" id="input-file" {...setInputProps("file", "", form)}></input>
                        <label for="input-file" className="input-file">
                            <div className="content-description">
                                <span className="title-description bold">Adjuntar archivo*</span>
                                <span className="sub-title-description">Doc, docx, jpg, png, pdf. Máximo 6MB</span>
                            </div>
                            <div className="clip">
                                
                            </div>
                        </label>
                    </div>
                    <div className="input">
                        <textarea placeholder="Mensaje*" {...setInputProps("message", "", form)}></textarea>
                    </div>
                    <div className="terminos">
                        <input type="checkbox" name="term" id="term" {...setInputProps("terms", "", form)}></input>
                        <label for="term" className="change"><div className="checked"></div></label>
                        <span className="">He leído la <span className="term-and-condition" onClick={()=>{dispatch(setActiveModal(true))}}>Política de Privacidad </span> y autorizo que se realicen las actividades de prospección comercial y marketing descritas.</span>
                    </div>
                    <button>
                        Enviar
                    </button>
                </form>)
            }}
            </FormContainer>
        </main> 
    )
}
