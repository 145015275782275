import axios from "axios";
import { InterestedEmail } from "../../../Assets/mailtemplates/InterestedEmail";
import {MailService} from "./MailService"

export class SperantService {
    constructor() {
        this.api = axios.create({
            baseURL: "https://api.sperant.com/v2/",
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": "Bearer R0PpTse4SK4AYGpSwBRJUqgEsnIsdfQJLJMQGl8pGjk"
            }
        })
        this.mailService = new MailService()
    }

    getProjectId(name) {
        return projects[name]
    }

    async createClient(data) {

        const client = {
            fname: data.fname,
            lname: data.lname,
            main_telephone: data.telephone,
            email: data.email,
            project_related: parseInt(data.project_id),
            interest_type_id: 5,
            input_channel_ids: 3,
            source_id: 8,
            observation: [
                data.rooms_amount && `Dorms: ${data.rooms_amount}`
            ].filter(Boolean).join(", ")
        }
        // prueba_avantte_utm@prueba.com
        if((/google|adwords|googleads|gclid|cpc/i).test(localStorage.getItem('url_query'))) (client.source_id = 16) // Google Ads
        if((/facebook|fbclid|pixel/i).test(localStorage.getItem('url_query'))) (client.source_id = 2) // facebook
        if((/icommarketing|mailchimp|mail/i).test(localStorage.getItem('url_query'))) (client.source_id = 17) // mailing

        new URLSearchParams(localStorage.getItem("url_query")).forEach((v, k) => {
            /utm_/.test(k) && (client[`${k.toLowerCase()}`] = v)
        })

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
        
        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1fCIi4ppPfMtzh_A6UwjW2kP2q9X-W3CFojrGvxoodRo",
            "range": "Web Biabo!A:XX",
            "values": [[
                date, data.fname, data.lname, data.email, data.telephone, data.project, data.rooms_amount,
                client.utm_source, client.utm_medium, client.utm_campaign, client.utm_term, client.utm_content
            ]]
        })
        if(data.project.toLowerCase() == "garden"){
            return this.mailService.from("Contacto Biabo inmobiliaria <contacto@biabo.pe>")
            .to("garden@biabo.pe")
            .subject(`Tienes un nuevo Lead para ${data.project}`)
            .html(InterestedEmail({...data, ...client}))
            .send()
        } else {
            return this.api.post("/clients", {data: client})
        }

    }

    async createBudget(budget) {
        return await this.api.post("/budgets", {data: budget})
    }
}

const projects = {
    nou: 16,
    salaverry: 7,
    trend: 18,
    town: 17,
}