import React, { Component } from 'react'
import "./AreasGaleria.scss"
import Slider from "react-slick";
import $ from 'jquery'
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery.js';
import 'lightgallery/dist/js/lightgallery-all.js';
import OwlCarousel from "react-owl-carousel2"

var area = "interiores-carousel";
const configModal = {
    selector: '.slide',
    thumbnail:true,
    animateThumb: false,
    showThumbByDefault: false,
    subHtmlSelectorRelative: true,
    rotate:false,
    actualSize:false,
    download:true,
    pinterest:false,
    googlePlus:false,
    twitter:true,

}


const optionsMiniatura = {
    items: 1,
    rewind: false,
    loop:true,
    nav:true,
    center:true,
    smartSpeed:700,
    mouseDrag: false,
    touchDrag: true,
    navText: [
        '<img class="w-100 h-100" src="./flecha-left.png"></img>',
        '<img class="w-100 h-100" src="./flecha-right.png"></img>'
    ],
    pullDrag: true,
};

var pos = 0
var xstart = 0;
var direction= "";

const events = {
    onChanged: function(e){
        try {
            let delay = setInterval(() => {
                let num = e.page.index
                num < 0 ? num = 0 : num = num
                let img = document.querySelector(`.multimedia .${area} .owl-item.active .slide img`).dataset.icon
                document.querySelector(".multimedia .icon-signal .icon").id = img
                $(`#miniatura-${area}`).trigger('to.owl.carousel',num+1)
                clearInterval(delay)
            }, 100);   
        } catch (error) {
            console.log(error)
        }
       
    }
}
const eventsMiniatura = {
    onChanged: function(e){

    }
}

export default class AreasGaleria extends Component {
    constructor(props){
        super(props)
        this.state = {
            proyecto:this.props.properties,
            nav1: null,
            nav2: null,
            nav3: null,
            nav4: null,
            interiorMiniatura:null,
            options :{
                items: 1,
                rewind: true,
                loop:true,
                nav:true,
                center:true,
                autoplayTimeout:7000,
                smartSpeed:1000,
                mouseDrag: true,
                touchDrag: true,
                navText: [
                    '<img class="w-100 h-100" src="./flecha-left.png"></img>',
                    '<img class="w-100 h-100" src="./flecha-right.png"></img>'
                ],
                pullDrag: true,
            },
            optionsCommon :{
                items: 1,
                rewind: false,
                loop:false,
                nav:true,
                center:true,
                autoplayTimeout:7000,
                smartSpeed:1000,
                mouseDrag: true,
                touchDrag: false,
                navText: [
                    '<img class="w-100 h-100" src="./flecha-left.png"></img>',
                    '<img class="w-100 h-100" src="./flecha-right.png"></img>'
                ],
                pullDrag: true,
            }
        }
        console.log(this.state.interiorMiniatura)
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    componentDidMount(){
        $('.slick-slider').lightGallery(configModal);
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            nav3: this.slider3,
            nav4: this.slider4,
            interiorMiniatura: this.state.interiorMiniatura
        });
        
    }
    galeria(areas){
        area = areas
        let img = document.querySelector(`.multimedia .${area} .owl-item.active  .slide img`).dataset.icon
        document.querySelector(".multimedia .icon-signal .icon").id = img

        $(".galeria-slick").removeClass("active")
        $(".title-multimedia").removeClass("active")
        $(`.${areas}`).addClass("active")
    }
    next() {
        var owl = $('#interiores-owlcarousel');
        owl.trigger('next.owl.carousel');
        direction = "right"
    }
    previous() {
        var owl = $('#interiores-owlcarousel');
        owl.trigger('prev.owl.carousel');
        direction = "left"
    }
    next2(){
        var owl2 = $('#areascomunes-owlcarousel');
        owl2.trigger('next.owl.carousel');
        direction = "right"
    }
    previous2(){
        var owl2 = $('#areascomunes-owlcarousel');
        owl2.trigger('prev.owl.carousel');
        direction = "left"
    }
    render() {
        return (
            <div>
                <section className="multimedia container mx-auto">
                    <div className="content-title" >
                        <div className='titles'>
                            <span className="title-multimedia interiores-carousel active" onClick={()=>{this.galeria("interiores-carousel")}}>INTERIORES</span>
                            <span className="title-multimedia areascomunes-carousel" onClick={()=>{this.galeria("areascomunes-carousel")}}>ÁREAS COMUNES</span>
                        </div>
                        <div className="miniaturas desktop">
                            <div className="miniatura-interior active">
                                <OwlCarousel ref="car" options={optionsMiniatura} events={eventsMiniatura} className="owl-carousel MyCarouselHorizontal interiores-carousel active" id="miniatura-interiores-carousel">
                                    { 
                                    this.state.proyecto.interiores.map((item,index)=>{
                                        return(
                                            <img className="img-expand" src={item.img}></img>
                                        )
                                    })}
                                </OwlCarousel>
                                <div className="expand-lupa galeria-slick interiores-carousel active" onClick={()=>{this.galeria("interiores-carousel")}}>
                                    <div className="content-lupa">
                                         <img className="img" src={require("../../Assets/images/iconos/svg/lupa.svg")} ></img>
                                    </div>
                                </div>
                            </div>
                            <div className="miniatura-areas-comunes">
                                <OwlCarousel ref="car" options={optionsMiniatura} events={eventsMiniatura} className="owl-carousel MyCarouselHorizontal interiores-carousel active" id="miniatura-areascomunes-carousel">
                                    {
                                         this.state.proyecto.areascomunes.map((item,index)=>{
                                             return(
                                                 <img className="img-expand" src={item.img}></img>
                                             )
                                         })
                                    }
                                </OwlCarousel>
                                <div className="expand-lupa galeria-slick areascomunes-carousel" onClick={()=>{this.galeria("areascomunes-carousel")}}>
                                    <div className="content-lupa">
                                         <img className="img" src={require("../../Assets/images/iconos/svg/lupa.svg")} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.proyecto.recorrido.url == "#" ? null : 
                            <a target='_blank' href={this.state.proyecto.recorrido.url} className="recorrido-virtual desktop">
                                <img src={this.state.proyecto.recorrido.img}></img>
                                <div className="d-flex content-title-recorrido">
                                    <span className="title-recorrido">RECORRIDO</span>
                                    <div className="icon-recorrido"></div>
                                </div>
                            </a>
                        }
                    </div>
                    <div className="content-slider">
                        <div className="icon-signal desktop">
                            <div className="icon" id="sofa" ></div>
                        </div>
                        <div className="galeria-slick interiores-carousel active slick-slider">
                            <OwlCarousel ref="car" options={this.state.options} events={events} className="owl-carousel MyCarouselHorizontal interiores-carousel active" id="interiores-owlcarousel">
                                {
                                    this.state.proyecto.interiores.map((item,index)=>{
                                        return(
                                            <div className="slide slick-slide" data-src={item.img} href={item.img} data-sub-html={"#index-"+index}>
                                            <img class="img-slide" src={item.img} data-icon={item.icon}></img>
                                            <div className="img-open">
                                                <span className="title-img desktop">{item.title}</span>
                                                <img className="movil" src={require("../../Assets/images/iconos/svg/lupa.svg")} ></img>
                                            </div>
                                            <div id={"index-"+index}>
                                                <h4>{item.title}</h4>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        <div className="galeria-slick areascomunes-carousel">
                            <OwlCarousel ref="car" options={this.state.optionsCommon} events={events} className="owl-carousel MyCarouselHorizontal areascomunes-carousel" id="areascomunes-owlcarousel">
                                {
                                    this.state.proyecto.areascomunes.map((item,index)=>{
                                        return(
                                            <div className="slide" data-src={item.img} href={item.img} data-sub-html={"#index-"+index}>
                                            <img class="img-slide" src={item.img} data-icon={item.icon}></img>
                                            <div className="img-open">
                                                <span className="title-img desktop">{item.title}</span>
                                                <img className="movil"src={require("../../Assets/images/iconos/svg/lupa.svg")}></img>
                                            </div>
                                            <div id={"index-"+index}>
                                                <h4>{item.title}</h4>
                                            </div>
                                        </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        <div className="controllers desktop">
                            <div className="icon icon-left galeria-slick interiores-carousel active" onClick={this.previous.bind(this)}>
                                <div className="left"></div>
                            </div>
                            <div className="icon icon-right galeria-slick interiores-carousel active" onClick={this.next.bind(this)}>
                                <div className="right"></div>
                            </div>
                            <div className="icon icon-left galeria-slick areascomunes-carousel" onClick={this.previous2.bind(this)}>
                                <div className="left"></div>
                            </div>
                            <div className="icon icon-right galeria-slick areascomunes-carousel" onClick={this.next2.bind(this)}>
                                <div className="right"></div>
                            </div>
                        </div>
                    </div>
                    <a href={this.state.proyecto.recorrido.url} className="recorrido-virtual movil">
                        <img src={this.state.proyecto.recorrido.img}></img>
                        <div className="d-flex content-title-recorrido">
                            <span className="title-recorrido">RECORRIDO</span>
                            <div className="icon-recorrido"></div>
                        </div>
                    </a>
                </section>
            </div>
        )
    }
}
