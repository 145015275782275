import React, { Component, useEffect } from 'react'
import { contactCenterSchema } from '../../components/common/forms/constraints/ValidatonSchemas'
import { FormContainer, setInputProps } from '../../components/common/forms/Form'
import './contactanos.scss'
import { setActiveModal } from '../../data/modal';
import { useDispatch } from 'react-redux';

export default function AtencionAlCliente () {
    const dispatch = useDispatch();
    const handleSubmit = () => {
        alert()
    }
    const initialValues = {
        names: "",
        email: "",
        telephone: "",
        project: "",
        apartament: "",
        file: "",
        message: "",
        terms: false
    }

    useEffect(()=>{
        document.querySelector("html").scrollTop = 0
        document.querySelector(".Navbar").classList.add("inactive")
        let links = document.querySelectorAll(".proyectos .project")
        links.forEach((e)=>{
            e.classList.remove("active")
        })
        document.querySelector("#link-servio-al-cliente").classList.add("active")
        
    }, [])

    return (
        <main className="Main-contacto contactanos">
            <section className="section-header">
                <h1 className="title text-center title-1 green">ATENCIÓN AL CLIENTE</h1>
                <span className="sub-title">Llena el formulario y un asesor se comuncará contigo.</span>
            </section>
            <FormContainer initialValues={initialValues} onSubmit={handleSubmit} validationSchema={contactCenterSchema}>
                {form =>{const {handleSubmit} = form;
                console.log(form)
                return(
                <form className="formulario" onSubmit={handleSubmit}>
                    <h2 className="title-form bold">Contáctanos</h2>
                    <div className="input"><input placeholder="Nombre y apellidos*" {...setInputProps("names", "", form)}></input></div>
                    <div className="input"><input placeholder="Correo electrónico*" {...setInputProps("email", "", form)}></input></div>
                    <div className="input"><input placeholder="Teléfono / Celular*" {...setInputProps("telephone", "", form)}></input></div>
                    <div className="input">
                        <select placeholder="Escoge un proyecto" {...setInputProps("telephone", "", form)}>
                            <option>Escoge un proyecto</option>
                            <option>Garden</option>
                            <option>Skyna</option>
                            <option>Avantte</option>
                        </select></div>
                    <div className="input"><input placeholder="Nro de departamento*" {...setInputProps("apartament", "", form)}></input></div>
                    <div className="input">
                        <input type="file" id="input-file" {...setInputProps("file", "", form)}></input>
                        <label for="input-file" className="input-file">
                            <div className="content-description">
                                <span className="title-description bold">Adjuntar archivo*</span>
                                <span className="sub-title-description">Doc, docx, jpg, png, pdf. Máximo 6MB</span>
                            </div>
                            <div className="clip">
                                
                            </div>
                        </label>
                    </div>
                    <div className="input">
                        <textarea placeholder="Mensaje*" {...setInputProps("message", "", form)}></textarea>
                    </div>
                    <div className="terminos">
                        <input type="checkbox" name="term" id="term" {...setInputProps("terms", "", form)}></input>
                        <label for="term" className="change"><div className="checked"></div></label>
                        <span className="">He leído la <span className="term-and-condition" onClick={()=>{dispatch(setActiveModal(true))}}>Política de Privacidad </span> y autorizo que se realicen las actividades de prospección comercial y marketing descritas.</span>
                    </div>
                    <button type="submit">Enviar</button>
                </form>)
                }}
                </FormContainer>
        </main>
    )
}
