import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async';
import OwlCarousel from "react-owl-carousel2";
import "./nosotros.scss"
const optionsAbout = {
    items: 1,
    loop:true,
    smartSpeed:1000,
    mouseDrag: true,
    touchDrag: true,
    nav:true,
    responsive:{
        0:{
            items:1,
            loop:true,
            margin:10,
        },
        700:{
            items:2,
            loop:true,
            margin:0,
            margin:30,
        },
        1100:{
            loop:true,
            margin:10,
            items:3,
        },
    },
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.92 28.84"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="14.42 26.34 2.5 14.42 14.42 2.5" fill="none" stroke="#1d3360" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.92 28.84"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><polyline points="2.5 2.5 14.42 14.42 2.5 26.34" fill="none" stroke="#1d3360" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/></g></g></svg>'
    ],
}
const events = {
    onTraslade:function(traslate){
    },
    onChanged:function(event){

    }
}
export default class AboutUs extends Component {
    constructor(props){
        super(props)
    }
   
    componentDidMount(){
        console.log(this.props.properties)
        document.querySelector("html").scrollTop = 0
        document.querySelector(".Navbar").classList.add("inactiveDesktop")

        let links = document.querySelectorAll(".proyectos .project")
        links.forEach((e)=>{
            e.classList.remove("active")
        })
        document.querySelector("#link-nosotros").classList.add("active")
    }
    render() {
        return (
            <main className="AboutUs">
                <Helmet>
                    <title>{this.props.properties.metaTitle}</title>
                    <meta  name="description" content={this.props.properties.metaDescription}/>
                    <meta  name="keywords" content={this.props.properties.metaKeyword}/>
                </Helmet>
                <div className="content-about">
                <div className="info-aboutus">
                    <span className="bold title">ACERCA DE NOSOTROS</span>
                    <p className="paragraph">Somos una empresa inmobiliaria con amplia
                        experiencia en el desarrollo y gestión de Proyectos
                        de Vivienda a nivel nacional.</p>
                    <p className="paragraph">
                        Ofrecemos a nuestros clientes su nueva vivienda con
                        diseños y acabados innovadores, <span>revalorizando su
                        inversión en el tiempo.</span>
                    </p>
                </div>
                <div className="content-slider">
                    <span className="bold title">NUESTROS PROYECTOS</span>
                    <OwlCarousel ref="car" options={optionsAbout} events={events} className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag">
                        {
                            this.props.properties.card.map((e)=>{
                                return(
                                <div className="card">
                                    <div className="shadow-card">
                                        <div className="content-img">
                                            <img className="img-expand" src={e.img}></img>
                                        <div className="place">{e.distrito}</div>
                                        </div>
                                        <div className="content-title-card">
                                            <span className="title-card bold d-block text-center">{e.title}</span>
                                            <span className="sub-title-card d-block text-center">{e.direccion}<span className="bold">{e.ref}</span></span>
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>
                </div>
            </main>
        )
    }
}
