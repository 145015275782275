import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "./Assets/scss/index.scss";
import Routes from "./routes";
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import $ from "jquery";
import store from './data/data'
window.$ = $
window.jQuery = window.$

if(window.location.search){
    localStorage.setItem('url_query', window.location.search)
} else {
    localStorage.setItem('url_query', '?utm_source=Orgánico')
}

ReactDOM.render(
    <Provider store={store}>
        <HelmetProvider>
            <Routes/>
        </HelmetProvider>
    </Provider>
    , document.getElementById('root'));
    
serviceWorker.unregister();
  