const garden = {
    id: 2,
    metaTitle:"Proyecto Garden | Departamentos en Chacarilla",
    title:"garden",
    metaDescription:"Flats y Dúplex de 2 y 3 dorms en Chacarilla. Espaciosos, elegantes y con los mejores acabados. Visita tu futuro Depa. Entrega inmediata. Cotiza aquí.",
    metaKeywords:"Biabo, Inmobiliaria, Departamentos,Flats, Duplex, vivienda, dormitorios, Garden, Chacarilla, Depas",
    sliderinicio:[
        {
            movil:require("../Assets/images/inicio/sliderInicio/render-garden.png"),
            desktop:require("../Assets/images/inicio/sliderInicio/render-garden.png"),
            brand:require("../Assets/images/inicio/sliderInicio/garden-logo.png"),
            title1:"SURCO",
            title2:"Av. Velasco Astete 1149",
            title3:'<span class="title-3">Depas de <span class="bold">2 y 3 dorms</span></span>',
        },
    ],
    render:{
        img:require("../Assets/images/inicio/sliderInicio/render-garden.png"),
        distrito:"Chacarilla",
        address:"Av. Velasco Astete",
        numero:"1149",
        avance:"Entrega inmediata",
        features:[
            {icon:"diamont",title:"Finos acabados"},
            {icon:"safety",title:"Seguridad"},
            {icon:"ubicacion",title:"Ubicación ideal"},
            {icon:"lock",title:"Privacidad"}
        ]
    },
    interiores:[
        {
            img:require("../Assets/images/garden/interiores/sala-doble-altura.jpg"),
            icon:"sofa",
            title:"Sala doble altura",
        },
        {
            img:require("../Assets/images/garden/interiores/sala-comedor.jpg"),
            icon:"sofa",
            title:"Sala comedor",
        },
        {
            img:require("../Assets/images/garden/interiores/sala-comedor-2.jpg"),
            icon:"sofa",
            title:"Sala comedor",
        },
        {
            img:require("../Assets/images/garden/interiores/kitchenette.jpg"),
            icon:"cocina",
            title:"Kitchenette",
        },
        {
            img:require("../Assets/images/garden/interiores/dormitorio-1.jpg"),
            icon:"dormitorio",
            title:"Dormitorio",
        },  
        {
            img:require("../Assets/images/garden/interiores/dormitorio-2.jpg"),
            icon:"dormitorio",
            title:"Dormitorio",
        },
        {
            img:require("../Assets/images/garden/interiores/dormitorio-3.jpg"),
            icon:"dormitorio",
            title:"Dormitorio",
        },
        {
            img:require("../Assets/images/garden/interiores/cocina.jpg"),
            icon:"cocina",
            title:"Cocina",
        },
        {
            img:require("../Assets/images/garden/interiores/baño_principal.jpg"),
            icon:"bath",
            title:"Baño principal",
        },
        {
            img:require("../Assets/images/garden/interiores/baño-visita.jpg"),
            icon:"bath",
            title:"Baño visita",
        },
    ],
    interiores:[
        {
            img:require("../Assets/images/garden/interiores/sala-comedor.jpg"),
            icon:"sofa",
            title:"Sala comedor",
        },
        {
            img:require("../Assets/images/garden/interiores/sala-comedor-2.jpg"),
            icon:"sofa",
            title:"Sala comedor",
        },
        {
            img:require("../Assets/images/garden/interiores/kitchenette.jpg"),
            icon:"cocina",
            title:"Kitchenette",
        },
        {
            img:require("../Assets/images/garden/interiores/dormitorio-1.jpg"),
            icon:"dormitorio",
            title:"Dormitorio",
        },  
        {
            img:require("../Assets/images/garden/interiores/dormitorio-2.jpg"),
            icon:"dormitorio",
            title:"Dormitorio",
        },
        {
            img:require("../Assets/images/garden/interiores/dormitorio-3.jpg"),
            icon:"dormitorio",
            title:"Dormitorio",
        },
        {
            img:require("../Assets/images/garden/interiores/cocina.jpg"),
            icon:"cocina",
            title:"Cocina",
        },
        {
            img:require("../Assets/images/garden/interiores/baño_principal.jpg"),
            icon:"bath",
            title:"Baño principal",
        },
        {
            img:require("../Assets/images/garden/interiores/baño-visita.jpg"),
            icon:"bath",
            title:"Baño visita",
        },
        {
            img:require("../Assets/images/garden/interiores/sala-doble-altura.jpg"),
            icon:"sofa",
            title:"Sala doble altura",
        },
    ],
    areascomunes:[
        {
            img:require("../Assets/images/garden/areascomunes/lobby.jpg"),
            icon:"lobby",
            title:"Lobby",
        },
    ],
    recorrido:{
        img:require("../Assets/images/skyna/recorrido-virtual/skyna.png"),
        url:"#"
    },
    tipologias:[
        {
            img:require("../Assets/images/garden/tipologias/tipologia1.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia1.png"),
            type:"002",
            meters:"202,45 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia2.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia2.png"),
            type:"004",
            meters:"195,75 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia3.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia3.png"),
            type:"005",
            meters:"194,15 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia4.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia4.png"),
            type:"101",
            meters:"134,25 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia5.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia5.png"),
            type:"102",
            meters:"157,30 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia6.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia6.png"),
            type:"104",
            meters:"150,80 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia7.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia7.png"),
            type:"105",
            meters:"152,30 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia8.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia8.png"),
            type:"201",
            meters:"156,10 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia9.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia9.png"),
            type:"202",
            meters:"157,20 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia10.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia10.png"),
            type:"204",
            meters:"148,60 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia11.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia11.png"),
            type:"205",
            meters:"152,60 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia12.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia12.png"),
            type:"301",
            meters:"153,15 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia13.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia13.png"),
            type:"303",
            meters:"152,89 m²",
            bath:"2 Baños",
            dorms:"2 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia14.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia14.png"),
            type:"304",
            meters:"150,80 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia15.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia15.png"),
            type:"305",
            meters:"152,30 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia16.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia16.png"),
            type:"401",
            meters:"256,84 m²",
            bath:"4 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia17.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia17.png"),
            type:"404",
            meters:"249,32 m²",
            bath:"3 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/garden/tipologias/tipologia18.png"),
            sketch:require("../Assets/images/garden/tipologias/croquis/mapatipologia18.png"),
            type:"405",
            meters:"254,02 m²",
            bath:"3 Baños",
            dorms:"Dormitorio servicio"
        },
      
    
    ],
    sites:[
        {
            site:"type active --education",
            title:"UNIVERSIDADES E INSTITUTOS",
            map:[
                {
                    title:"MAD",
                    adress:"Av. Primavera 850",
                },
                {
                    title:"Toulouse Lautrec",
                    adress:"Av. Primavera 970",
                },
                {
                    title:"Idiomas Catolica",
                    adress:"Av. Primavera 907",
                },
                {
                    title:"Universidad Peruana de Ciencias Aplicadas",
                    adress:"Prolongación Primavera 2390",
                },
                {
                    title:"Asociación Cultural Peruano Británica",
                    adress:"Av. Primavera 1770",
                },
            ]
        },
        {
            site:"type --colegios",
            title:"COLEGIOS",
            map:[
                {
                    title:"Colegio Santa María Marianistas",
                    adress:"Av. de la Floresta 250",
                },
                {
                    title:"Colegio Hans Christian Andersen ",
                    adress:"Jr. Alonso de Molina 136",
                },
                {
                    title:"Colegio Franco Peruano",
                    adress:"Jirón Morro Solar 550",
                },
                {
                    title:"Colegio Virgen Inmaculada",
                    adress:"Jirón Morro Solar 110",
                },
                {
                    title:"Colegio Nuestra Señora Del Consuelo",
                    adress:"Jirón Tambo Real 165",
                },
            ]
        },
        {
            site:"type --restaurant",
            title:"RESTAURANTES",
            map:[
                {
                    title:"Don Mamino",
                    adress:"Av. Primavera 1803",
                },
                {
                    title:"Hanzo",
                    adress:"Av. Primavera 1494",
                },
                {
                    title:"KFC",
                    adress:"Av. Primavera 1188",
                },
                {
                    title:"Pescados Capitales",
                    adress:"Av. Primavera 1067",
                },
                {
                    title:"Chili's",
                    adress:"Av. Alejandro Velasco Astete 1002",
                },
                {
                    title:"Fridays",
                    adress:"Av. Primavera 737",
                },
                {
                    title:"La Basilica 640 ",
                    adress:"Av. Primavera 640",
                },
                {
                    title:"La Bistecca",
                    adress:"Av. Primavera 545",
                },
                {
                    title:"La Bodega de la Trattoria",
                    adress:"Av. Primavera 712",
                },
                {
                    title:"Punta Sal",
                    adress:"Av. Primavera 443",
                },
                {
                    title:"Kilimanjaro ",
                    adress:"Av. Primavera 464",
                },
                {
                    title:"Starbucks ",
                    adress:"Av. Caminos del Inca 414",
                },
            ]
        },
        {
            site:"type --charge",
            title:"ESTACIONES DE SERVICIO",
            map:[
                {
                    title:"Grifo Primax",
                    adress:"Av. Arequipa 979",
                },
                {
                    title:"Repsol",
                    adress:"Avenida Primavera 1120 Urb",
                },
            ]
        }
        
    ],
    ubicacion:{
        img:"/site/site-garden.png",
        coord:{x:-76.9840432322471,y:-12.114360750163705},
        size: 0.25,
    }
}
export default garden