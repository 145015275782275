import React, { Component } from 'react'
import { Link } from "react-router-dom";
import './card.scss'
import OwlCarousel from "react-owl-carousel2";

const options = {
    rewind: true,
    loop:true,
    smartSpeed:700,
    responsive:{
        0:{
            items:2,
            center:true,
            margin:20

        },
        700:{
            loop:true,
            mouseDrag:true,
            touchDrag: true,
            center:true,
            items:4
        },
        900:{
            loop:false,
            mouseDrag:false,
            touchDrag: false,
            items:4
        }
    },
    navText: [
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.79 27.37"><defs><style>.flecha-left{fill:none;stroke:#003061;stroke-miterlimit:10;stroke-width:5px;}</style></defs><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><line class="flecha-left" x1="3.54" y1="13.69" x2="34.79" y2="13.69"/><polyline class="flecha-left" points="15.46 25.61 3.54 13.69 15.46 1.77"/></g></g></svg>',
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.79 27.37"><defs><style>.flecha-right{fill:none;stroke:#003061;stroke-miterlimit:10;stroke-width:5px;}</style></defs><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><line class="flecha-right" x1="31.25" y1="13.69" y2="13.69"/><polyline class="flecha-right" points="19.33 1.77 31.25 13.69 19.33 25.61"/></g></g></svg>'
    ],
};
const events = {
    onChanged: function(event){

    } 
}
export default class Card extends Component {
    
    render() {
        const array = [
            {
            id: "Garden",
            brand: require("../../Assets/images/iconos/garden.png"),
            alt:"",
            img: require("../../Assets/images/inicio/cards/garden.JPG"),
            type: "Entrega inmediata",
            ubicacion: "Av. Velasco Astete 1149,",
            distrito:"Chacarilla",
            dorms: '2 y 3 dormitorios',
            desde: "134m²",
            hasta: "259m²",
            url: "/garden",
        },
            {
            id: "Skyna",
            brand: require("../../Assets/images/iconos/skyna.png"),
            alt:"",
            img: require("../../Assets/images/inicio/cards/skyna.JPG"),
            type: "En construcción",
            ubicacion: "Calle Enrique Villar 581,",
            distrito:"Santa Beatriz",
            dorms: '2 y 3 dormitorios',
            desde: "52m²",
            hasta: "70m²",
            url: "/skyna",
        },
            {
            id: "Avantte",
            brand: require("../../Assets/images/iconos/avantte.png"),
            alt:"",
            img: require("../../Assets/images/inicio/cards/avantte.JPG"),
            type: "Preventa",
            ubicacion: "Av. Ícaro 162,",
            distrito:"Chorrillos",
            dorms: '2 y 3 dormitorios',
            desde: "49m²",
            hasta: "62m²",
            ver: "Ver proyecto",
            url: "/avantte" ,
        },
            {
            id: "Proximo",
            brand:"",
            alt:"",
            img: require("../../Assets/images/inicio/cards/nuevo-proyecto.JPG"),
            type: "Próximo Lanzamiento",
            ubicacion: "Miraflores",
            dormitorio: "",
            dorms: '1 y 2 Dorms',
            desde: "54.80m²",
            hasta: "78.02m²",
            url: "#",
        },


    ]
        return (
            <div className="Proyectos" id="Proyectos">
                <div className="Content_proyectos">
                    <div className="selectores">
                        <h1 className="title"  id="proyectos">Nuestros Proyectos</h1>
                    </div>
                <div className="builds">
                <OwlCarousel ref="car" options={options} events={events} className="owl-carousel MyCarouselHorizontal none owl-theme owl-loaded owl-drag" id="carousel-1">
                    {array.map((element)=>{
                        return (
                        <Link to={element.url} className={"items img1"}>
                            <div  className="build">
                                <div className="build-header flex-row">
                                    <div className="brand-header">                                     
                                        <img alt={element.alt} className='' src={element.brand}></img>
                                    </div>
                                    <div className='type-proyect'>{element.type}</div>
                                </div>
                                <div className='img-build'>
                                    <img alt="edificio biabo" className='' src={element.img}></img>
                                </div>
                                <div className='info-build'>
                                    <div className="paragraph">
                                        <span className="direccion">{element.ubicacion} <span className="bold">{element.distrito}</span></span>
                                        <span className="dorms">{element.dorms}</span>
                                        <span className="attributes d-block ">Desde <span className="atributos bold">{element.desde}</span> Hasta <span className="atributos bold">{element.hasta}</span></span>
                                    </div>
                                    <div className="button-look">Más información</div>
                                </div> 
                            </div>
                        </Link>
                        )
                    })}
                  </OwlCarousel>
                </div>
                </div>
            </div>
        )
    }
}
