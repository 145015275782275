import React, { Component } from 'react'
import './contacto.scss'
import { FormContainer } from '../../components/common/forms/Form'
import { setInputProps } from '../common/forms/Form'
import { ContactUsValidationSchema } from '../common/forms/constraints/ValidatonSchemas'
import { setActiveModal } from '../../data/modal'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { SperantService } from '../common/Http/api'

export default function Contacto(props) {
    const dispatch =  useDispatch();
    const submitForm = (values, { setSubmitting, resetForm }) => {
        values.project_id = values.project
        values.project = {
            "2": "Garden",
            "4": "Skyna",
            "1": "Avantte",
        }[values.project]

        const sperant = new SperantService()
        sperant.createClient(values).then(_=>{
            setSubmitting(false)
            Swal.fire({
                title: '',
                icon: 'success',
                text: 'Datos enviados',
                confirmButtonText: 'Cerrar',
            })
        }).catch(_=>{
            Swal.fire({
                title: '',
                icon: 'error',
                text: 'Error',
                confirmButtonText: 'Cerrar',
            })
        })
    }
    const initialValues = {
        fname: "",
        lname: "",
        telephone: "",
        email: "",
        project: "",
        terms: false,
        rooms_amount:""
    }
    return (
        <div className={"contacto"} id="Contactanos">
                <h2 className="title">Empieza a construir tu historia aquí</h2>
                <p className="paragraph">Completa el formulario para comunicarnos contigo</p>
                <FormContainer initialValues={initialValues} onSubmit={submitForm} validationSchema={ContactUsValidationSchema}>
                {form =>{const {handleSubmit} = form;
                console.log(form)
                return(
                <form className="formulario" onSubmit={handleSubmit}>
                    <div className="flex-column">
                        <label class="input-title">Nombre*</label>
                        <input type="text" {...setInputProps("fname", "input ", form)}></input>
                    </div>
                    <div className="flex-column">
                        <label class="input-title" >Apellido*</label>
                        <input type="text" {...setInputProps("lname", "input", form)}></input>
                    </div>
                    <div className="flex-column">
                        <label class="input-title">Teléfono*</label>
                        <input type="tel" {...setInputProps("telephone", "input ", form)}></input>
                    </div>
                    <div className="flex-column">
                        <label class="input-title">Correo*</label>
                        <input type="email" {...setInputProps("email", "input", form)}></input>
                    </div>
                    <div className="flex-column">
                        <label class="input-title">Proyecto*</label>
                        <select {...setInputProps("project", "input", form)}>
                            <option selected disabled value="">¿En qué proyecto estás interesado?</option>
                            <option value="4">Proyecto Skyna</option>
                            <option value="1">Proyecto Avantte</option>
                            <option value="2">Proyecto Garden</option>
                        </select>
                    </div>
                    <div className="flex-column">
                        <label  class="input-title">Número de dormitorios*</label>
                        <select className="input" {...setInputProps("rooms_amount", "input col", form)}>
                            <option selected disabled value="">¿Cuántos dormitorios estás buscando?</option>
                            <option value="2">2 dormitorios</option>
                            <option value="3">3 dormitorios</option>
                        </select>
                    </div>
                    <div className="flex-column button-radius">
                        <div className="radio flex-row">                            
                            <label className="button-title flex-row" for="terminos">
                                <input type="checkbox" id="terminos" {...setInputProps("terms", "input col boton-radio", form)}></input>
                                <div className="radius" id="link-terminos"></div> 
                                <span className="politics">He leído la <span onClick={()=>{dispatch(setActiveModal(true))}} className="term-and-condition">Política de Privacidad</span> y autorizo que se realicen las actividades de prospección comercial y marketing descritas.</span>
                            </label>
                        </div>
                    </div>
                    <div className="btn">
                        <button type="submit" className="btn-submit" >Enviar</button>
                    </div>
                </form>
                    )
                }}
                </FormContainer>
            </div>
    )
}
