import { createSlice } from "@reduxjs/toolkit";

const modal = createSlice({ 
    name: "modal",
    initialState: {
        activeModal: false,
    },
    reducers: {
        setActiveModal: (state, action) => {
            state.activeModal = action.payload;
        },
    }
});
export const { setActiveModal } = modal.actions;
export default modal.reducer;