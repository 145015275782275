export const InterestedEmail = data => `
<table align="center" border="0" cellpadding="0" cellspacing="0" style="">
	<tbody>
        <tr>
	        <td style="word-wrap:break-word;font-size:0;padding:10px 25px;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0" align="center">
				<a href="https://biabo.pe"><img src="https://resources.formulaperu.com/uploads/0xrahryn32fz0di.jpg"></a>
	        </td>
        </tr>
        <tr>
	    </tr><tr width="100%">
                    <td valign="top" align="left" style="background:#fff;padding:18px">
                        <h1 style="font-size:20px;margin:16px 0;color: #00224f;text-align:center;font-family: Arial;">${ data.fname }  quiere más información  sobre <br> ${ data.project }</h1>

                        <p style="font: 15px/1.25em Arial;color: #00224f;text-align:center;border-bottom: 1px solid #00224f29;;padding-bottom: 20px;">La siguiente información proviene del formulario de la página web.</p>
                        <div style="background:#ffff;border-radius:3px">
                            <p style="padding-top: 10px;padding-bottom: 10px;text-align:center">
                                <a href="#" style="color:#00224f;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;font-weight: 100;" target="_blank">
                                    <span style="display: block;font-size: .9rem;">
                                        Nombre Completo
                                    </span>
                                    <span style="display: block;font-size: 1.1rem;">${ data.fname } ${ data.lname }</span>
                                </a>
                            </p>
                            <p style="padding-top: 10px;padding-bottom: 10px;text-align:center">
                                <a href="mailto:${ data.email }" style="color:#00224f;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;" target="_blank">
                                    <span style="display: block;font-size: .9rem;">
                                        Correo Electrónico
                                    </span>
                                    <span style="display: block;font-size: 1.1rem;">
                                        ${ data.email }
                                    </span>
                                </a>
                            </p>
                            <p style="padding-top: 10px;padding-bottom: 10px;text-align:center">
                                <a href="tel:${ data.telephone }" style="color:#00224f;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;" target="_blank">
                                    <span style="display: block;font-size: .9rem;">
                                        Teléfono
                                    </span>
                                    <span style="display: block;font-size: 1.1rem;">
                                        ${ data.telephone }
                                    </span>
                                </a>
                            </p>
                            <p style="padding-top: 10px;padding-bottom: 10px;text-align:center">
                                <a href="#" style="color:#00224f;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;" target="_blank">
                                    <span style="display: block;font-size: .9rem;">
                                        Proyecto
                                    </span>
                                    <span style="display: block;font-size: 1.1rem;">
                                        ${ data.project }
                                    </span>
                                </a>
                            </p>
                            <p style="padding-top: 10px;padding-bottom: 10px;text-align:center;margin-bottom: 20px;">
                                <a href="#" style="color:#00224f;font:16px/1.25em 'Helvetica Neue',Arial,Helvetica;text-decoration:none;" target="_blank">
                                    <span style="display: block;font-size: .9rem;">
                                        Mensaje 
                                    </span>
                                    <span style="display: block;font-size: 1.1rem;">
                                        ${ data.observation }
                                    </span>
                                </a>
                            </p>
                        </div>
                    </td>
                </tr>
			<tr><td>
				<a href="https://www.formulaperu.com"><img src="https://resources.formulaperu.com/uploads/zgx3j5rqogudceo.jpg"></a>               	
			</td>
		</tr>
	</tbody>
</table>
`