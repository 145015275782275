import React, { useEffect, useState }  from 'react';
import { setActiveModal } from '../../data/modal';
import { useDispatch, useSelector} from 'react-redux';


export default function ModalPoliticas (props){
    const dispatch = useDispatch()
    const [data,setData] = useState()
    const modal = useSelector((state) => state.modal)
    const closeModal = (e)=>{
        if(e.target.dataset.type == 'modal'){
            console.log(e.target.dataset.type)
            dispatch(setActiveModal(false))
        }
    }
    useEffect(()=>{
        if(modal.activeModal == true){
            setData(
                `<h2 class='text-center'>POLÍTICA DE PRIVACIDAD</h2>
                <div class='overflow'>
                    <p class='text-center sub-title'>CONDICIONES DE TRATAMIENTO DE DATOS PERSONALES</p>
    
                    <p>Mis datos personales serán registrados en el banco de datos “Prospectos de Clientes” (Código: RNPDP-PJP N° 14368) de titularidad de BIABO Perú S.A., y podrán ser transferidos a una o más de sus empresas vinculadas, las cuales administran distintos proyectos inmobiliarios de Biabo Inmobiliaria domiciliado en Ca. Monte Rosa 270. Int. 1004, Santiago de Surco, Lima.</p>
                    <p class=''>Los datos personales materia de tratamiento autorizado son los que he suministrado a través del formulario virtual de la página web de Biabo Inmobiliaria. Las finalidades determinadas, explícitas y lícitas son: (i) Dar cuenta de las consultas sobre información sobre los proyectos y cotizaciones de inmuebles, así como atender cualquier otro requerimiento de información del usuario con relación a la oferta inmobiliaria de Biabo Inmobiliaria; y (iii) Sólo en caso lo autorice expresamente mediante checkbox adicional, Biabo Inmobiliaria podrá realizar actividades de prospección comercial y de marketing, las que incluyen informarme sobre las ofertas, promociones, beneficios, concursos y publicidad en general de los proyectos actuales y futuros de Biabo Inmobiliaria, así como participar de encuestas de satisfacción, análisis de consumo, preferencias y tendencias de mercado. De no autorizar expresamente las actividades de prospección comercial y de marketing, éstas no podrán llevarse a cabo.</p>
                    <p class=''>Se me ha informado que el tratamiento de mis datos personales será realizado directamente por Biabo Inmobiliaria y por las siguientes terceras empresas encargadas, que pueden encontrarse dentro o fuera del Perú:</p>
                    
                    <ul>
                        <li>
                            <span class='ball'></span>
                            <span class='text'>
                                Fórmula Agencia de Marketing S.A.C., con domicilio en Av. Pq. G. Prada 565, int 503, Magdalena, Lima, Perú, encargado de la administración de la página web.
                            </span>
                        </li>
                        <li>
                            <span class='ball'></span>
                            <span class='text'>
                                Microsoft Inc, ubicada en One Microsoft Way, Redmond, WA 98052-7329, Estados Unidos, que se encarga del respaldo de información.
                            </span>
                        </li>
                        <li>
                            <span class='ball'></span>
                            <span class='text'>
                            HostGator.com LLC,  Con sede en Houston, Texas,  – Estados Unidos, encargado de brindar el servicio de host de la página web
                            </span>
                        </li>
                    </ul>
                    <p class=''>Cualquier variación de los encargados de datos personales será comunicada a mi correo electrónico. </p>
                    <p class=''>He sido informado que: (i) Biabo Inmobiliaria garantiza la confidencialidad y el adecuado procesamiento de mis datos personales; (ii) el plazo de almacenamiento será aquél necesario para atender mi requerimiento de información o cotización, el cual será como máximo [2 semanas], luego del cual mis datos personales serán eliminados; (iii) en caso autorice actividades de prospección comercial y de marketing, el plazo de almacenamiento será indeterminado; y, (iv) podré ejercer los derechos los derechos de acceso, rectificación, cancelación, oposición, información o revocación que por ley me asisten, remitiendo una comunicación a los correos electrónicos <a href='mailto:contacto@biabo.pe'>contacto@biabo.pe</a></p>
                </div>
                <div class="close" data-type='modal'></div>`
            )
        }
    },[modal])
    return (
        <div className={`modal ${modal.activeModal == true ? "active":""}`} onClick={(e)=>{closeModal(e)}} data-type='modal'>
            <div className="content-modal" dangerouslySetInnerHTML={{__html:data}}>
            </div>
        </div>
    );
}
