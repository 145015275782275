const skyna = {
    id: 4,
    metaTitle:"Proyecto Skyna | Departamentos en Santa Beatriz",
    title:"skyna",
    metaDescription:'Departamentos de 2 y 3 dorms en Santa Beatriz en una ubicación estratégica a pocas cuadras de la Av. Arequipa. Estamos en construcción. Cotiza aquí.',
    metaKeywords:'Biabo, Inmobiliaria, Departamentos, vivienda, bono verde, dormitorios, Skyna, Santa Beatriz, Av. Arequipa, Construcción, Depas',
    
    sliderinicio:[
        {
            movil:require("../Assets/images/skyna/sliderPrincipal/movil/01.jpg"),
            desktop:require("../Assets/images/inicio/sliderInicio/render-skyna.png"),
            brand:require("../Assets/images/inicio/sliderInicio/skyna-logo.png"),
            title1:"SANTA BEATRIZ",
            title2:"Ca. Enrique Villar 581",
            title3:'<span class="title-3">Depas de <span class="bold">2 y 3 dorms</span></span>',
        },
        // {
        //     banner:{movil:require("../Assets/images/inicio/sliderInicio/banner-movil.jpg"),desktop:require("../Assets/images/inicio/sliderInicio/banner-desktop.jpg")},
        // },
    ],
    render:{
        img:require("../Assets/images/inicio/sliderInicio/render-skyna.png"),
        distrito:"SANTA BEATRIZ",
        address:"Calle Enrique Villar",
        numero:"581",
        avance:"En construcción",
        features:[
            {icon:"facilidad",title:"Precio insuperable"},
            {icon:"precio",title:"Facilidad de pago"},
            {icon:"ubicacion",title:"Ubicación ideal"},
            {icon:"acabados",title:"Acabados de calidad"}
        ]

    },
    areascomunes:[
        {
            img:require("../Assets/images/skyna/areascomunes/Lobby.jpg"),
            icon:"lobby",
            title:"Lobby",
        },
    ],
    interiores:[
        {
            img:require("../Assets/images/skyna/interiores/cocina.jpg"),
            icon:"cocina",
            title:"Cocina",
        },
        {
            img:require("../Assets/images/skyna/interiores/dorm-principal.jpg"),
            icon:"dormitorio",
            title:"Dormitorio principal",
        },
        {
            img:require("../Assets/images/skyna/interiores/dorm-secundario.jpg"),
            icon:"dormitorio",
            title:"Dormitorio secundario",
        },
        {
            img:require("../Assets/images/skyna/interiores/sala-comedor.jpg"),
            icon:"sofa",
            title:"Sala comedor",
        },
        {
            img:require("../Assets/images/skyna/interiores/bath.jpg"),
            icon:"bath",
            title:"Baño",
        },
    ],
    
    recorrido:{
        img:require("../Assets/images/skyna/recorrido-virtual/skyna.png"),
        url:"https://biabo.pe/tour360/Skyna"
    },
    tipologias:[
        {
            img:require("../Assets/images/skyna/tipologias/201A-1301A.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/201A-1301A.png"),
            type:"201A-1301A",
            meters:"70 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/201B-1401B.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/201B-1401B.png"),
            type:"201B-1401B",
            meters:"69 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/202A-1302A.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/202A-1302A.png"),
            type:"202A-1302A",
            meters:"67 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/202B-1302B.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/202B-1302B.png"),
            type:"202B-1302B",
            meters:"62 m²",
            bath:"2 Baños",
            dorms:"2 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/203A-1303A.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/203A-1303A.png"),
            type:"203A-1303A",
            meters:"52 m²",
            bath:"2 Baños",
            dorms:"2 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/203B-1303B.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/203B-1303B.png"),
            type:"203B-1303B",
            meters:"64 m²",
            bath:"2 Baños",
            dorms:"2 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/204A-1404A.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/204A-1404A.png"),
            type:"204A-1404A",
            meters:"69 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/204B-1204B.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/204B-1204B.png"),
            type:"204B-1204B",
            meters:"70 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/205B-1205B.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/205B-1205B.png"),
            type:"205B-1205B",
            meters:"69 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/skyna/tipologias/206B-1206B.png"),
            sketch:require("../Assets/images/skyna/tipologias/sketch/206B-1206B.png"),
            type:"206B-1206B",
            meters:"70 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },

    ],
    sites:[
        {
            site:"type active --education",
            title:"UNIVERSIDADES E INSTITUTOS",
            map:[
                {
                    title:"Instituto Italiano de Cultura",
                    adress:" Av. Arequipa 1055",
                },
                {
                    title:"Instituto de Ciencia y Gestion",
                    adress:"Jr. Jirón Larrabure y Unanue 231",
                },
                {
                    title:"Instituto SISE",
                    adress:"Av. Arequipa 1290",
                },
                {
                    title:"Universidad Arzobispo Loayza",
                    adress:"Av. Arequipa 979",
                },
            ]
        },
        {
            site:"type --colegios",
            title:"COLEGIOS",
            map:[
                
                {
                    title:"Colegio Trilce - Santa Beatriz",
                    adress:"Ca. Emilio Fernández 641",
                },
                {
                    title:"Colegio Cristiano Internacional Elim",
                    adress:"Calle Mariano Carranza 549",
                },
                {
                    title:"Colegio Dante Alighieri",
                    adress:"Mariano Carranza 263",
                },
                {
                    title:"Colegio Los Robles",
                    adress:"Jr. Carlos Arrieta 1249",
                },
                {
                    title:"Colegio Saco Oliveros - Montessori",
                    adress:"Teodoro Cárdenas 437",
                },
                {
                    title:"Colegio Sa Olivero - Arequipa",
                    adress:"Av. Arequipa 1206",
                },
                {
                    title:"Colegio Ruben Dario",
                    adress:"Manuel del Pino 176",
                },
                {
                    title:"Colegio Trilce",
                    adress:"Av. Arequipa 1381",
                },
            ]
        },
        {
            site:"type --restaurant",
            title:"RESTAURANTES",
            map:[
                {
                    title:"Starbucks",
                    adress:"Teodoro Cárdenas 182",
                },
                {
                    title:"Telepizza",
                    adress:"Av. Arequipa 1200",
                },
                {
                    title:"PizzaHut",
                    adress:"Teodoro Cárdenas 176",
                },
                {
                    title:"Cosita Ricas Restaurant",
                    adress:"Av. Petit Thouars 1288",
                },
                {
                    title:"Norky´s",
                    adress:"Av. Gral. Salaverry 943- 945",
                },
            ]
        },
        {
            site:"type --charge",
            title:"ESTACIONES DE SERVICIO",
            map:[
                {
                    title:"Primax",
                    adress:"Av. Arequipa 979",
                },
                {
                    title:"Grifo terpel",
                    adress:"Av. Arequipa 1290",
                },
            ]
        }
        
    ],
    ubicacion:{
        img:"/site/mapa.png",
        coord:{x:-77.032365,y:-12.075946},
        size: 0.4,
    }
}
export default skyna