import skyna from './skyna'
import garden from './garden'
import avantte from './avantte';
import aboutUsInfo from './about';


const global = {
    skyna:skyna,
    garden:garden,
    avantte:avantte,
    aboutUsInfo:aboutUsInfo,
}

export default  global;