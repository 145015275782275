const avantte = {
    id: 1,
    metaTitle:"Proyecto Avantte | Departamentos en Chorrillos",
    title:"avantte",
    metaDescription:'Departamentos de 2 y 3 dorms en Chorrillos con increíbles áreas comunes. Proyecto MiVivienda Verde. Pronto inicio de obra. Cotiza aquí.',
    metaKeywords:'Biabo, Inmobiliaria, Departamentos, vivienda, bono verde, dormitorios, Avantte, Chorrillos, Depas',
    sliderinicio:[
        {
            movil:require("../Assets/images/inicio/sliderInicio/render-avantte.png"),
            desktop:require("../Assets/images/inicio/sliderInicio/render-avantte.png"),
            brand:require("../Assets/images/inicio/sliderInicio/avantte-logo.png"),
            title1:"CHORRILLOS",
            title2:"Calle Icaro 162",
            title3:'<span class="title-3">Depas de <span class="bold">2 y 3 dorms</span></span>',
        },
    ],
    render:{
        img:require("../Assets/images/inicio/sliderInicio/render-avantte.png"),
        distrito:"CHORRILLOS",
        address:"Calle Icaro",
        numero:"162",
        avance:"Preventa",
        features:[
            {icon:"facilidad",title:"Precio insuperable"},
            {icon:"precio",title:"Facilidad de pago"},
            {icon:"ubicacion",title:"Ubicación ideal"},
            {icon:"parrilla",title:"Áreas comunes de lujo"}
        ]
    },
    interiores:[
        {
            img:require("../Assets/images/avantte/interiores/Sala.jpg"),
            icon:"sofa",
            title:"Sala",
        },
        {
            img:require("../Assets/images/avantte/interiores/cocina.jpg"),
            icon:"cocina",
            title:"Cocina",
        },
        {
            img:require("../Assets/images/avantte/interiores/dormitorio-principal.jpg"),
            icon:"dormitorio",
            title:"Dormitorio principal",
        },
        {
            img:require("../Assets/images/avantte/interiores/dormitorio-secundario.jpg"),
            icon:"dormitorio",
            title:"Dormitorio secundario",
        },
        {
            img:require("../Assets/images/avantte/interiores/bano.jpg"),
            icon:"bath",
            title:"Baño",
        },
    ],
    interioresSecond:[
        {
            img:require("../Assets/images/avantte/interiores/cocina.jpg"),
            icon:"cocina",
            title:"Cocina",
        },
        {
            img:require("../Assets/images/avantte/interiores/dormitorio-principal.jpg"),
            icon:"dormitorio",
            title:"Dormitorio principal",
        },
        {
            img:require("../Assets/images/avantte/interiores/dormitorio-secundario.jpg"),
            icon:"dormitorio",
            title:"Dormitorio secundario",
        },
        {
            img:require("../Assets/images/avantte/interiores/bano.jpg"),
            icon:"bath",
            title:"Baño",
        },
        {
            img:require("../Assets/images/avantte/interiores/Sala.jpg"),
            icon:"sofa",
            title:"Sala",
        },
    ],
    areascomunes:[
        {
            img:require("../Assets/images/avantte/areascomunes/gym-1.jpg"),
            icon:"pesa",
            title:"Gimnasio",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/gym-2.jpg"),
            icon:"pesa",
            title:"Gimnasio",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/juegos-1.jpg"),
            icon:"boliche",
            title:"Zona full fun",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/juegos-2.jpg"),
            icon:"boliche",
            title:"Zona full fun",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/ninos-1.jpg"),
            icon:"child",
            title:"Zona de niños",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/ninos-2.jpg"),
            icon:"child",
            title:"Zona de niños",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/parrilla-1.jpg"),
            icon:"parrilla",
            title:"Zona de parrillas",
        },
        {
            img:require("../Assets/images/avantte/areascomunes/parrilla-2.jpg"),
            icon:"parrilla",
            title:"Zona de parrillas",
        },
    ],
    recorrido:{
        img:require("../Assets/images/skyna/recorrido-virtual/skyna.png"),
        url:"https://biabo.pe/tour360/Avantte"
    },
    tipologias:[
        {
            img:require("../Assets/images/avantte/tipologias/modelo-a.png"),
            sketch:require("../Assets/images/avantte/tipologias/croquis/modelo-a.png"),
            type:"Modelo A",
            meters:"62 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/avantte/tipologias/modelo-b.png"),
            sketch:require("../Assets/images/avantte/tipologias/croquis/modelo-b.png"),
            type:"Modelo B",
            meters:"58 m²",
            bath:"2 Baños",
            dorms:"3 Dormitorios"
        },
        {
            img:require("../Assets/images/avantte/tipologias/modelo-c.png"),
            sketch:require("../Assets/images/avantte/tipologias/croquis/modelo-c.png"),
            type:"Modelo C",
            meters:"49 m²",
            bath:"1 Baño",
            dorms:"2 Dormitorios"
        },
        {
            img:require("../Assets/images/avantte/tipologias/modelo-d.png"),
            sketch:require("../Assets/images/avantte/tipologias/croquis/modelo-d.png"),
            type:"Modelo D",
            meters:"32 m²",
            bath:"1 Baño",
            dorms:"1 Dormitorio"
        },

    ],
    sites:[
        {
            site:"type active --education",
            title:"UNIVERSIDADES E INSTITUTOS",
            map:[
                {
                    title:"Instituto Superior Tecnológico Público Antenor Orrego",
                    adress:" Av. Ciro Alegrí­a, cuadra 2",
                },
                {
                    title:"Escuela de Oficiales de la Policía Nacional del Perú",
                    adress:" Av. Guardia Civil Sur 8",
                },
            ]
        },
        {
            site:"type --colegios",
            title:"COLEGIOS",
            map:[
                
                {
                    title:"Innova School",
                    adress:"Calle Universo",
                },
                {
                    title:"Innova School",
                    adress:"Av. Los Faisanes",
                },
                {
                    title:"Colegio Belen",
                    adress:"Jr. Teniente Carlos Jimenez Chavez 182",
                },

            ]
        },
        {
            site:"type --restaurant",
            title:"RESTAURANTES",
            map:[
                {
                    title:"Papa John's",
                    adress:"Av. Prol. Huaylas cdra. 20",
                },
                {
                    title:"Domino's Pizza",
                    adress:"Avenida defensores del morro cdra. 22",
                },
            ]
        },
        {
            site:"type --charge",
            title:"ESTACIONES DE SERVICIO",
            map:[
                {
                    title:"PetroPeru",
                    adress:"Av. Prol. Huaylas 2280",
                },
                {
                    title:"Repsol Moligrifo",
                    adress:"Av. Defensores del Morro 2671",
                },
                {
                    title:"Repsol",
                    adress:"Av. Guardia Civil Sur 605",
                },
            ]
        }
        
    ],
    ubicacion:{
        img:"/site/site-avantte.png",
        coord:{x:-77.000625,y:-12.175899},
        size: 0.3,
    }
}
export default avantte