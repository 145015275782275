const about = {
    metaTitle:'Nosotros | Biabo Inmobiliaria',
    metaDescription:"Somos una empresa inmobiliaria con amplia experiencia en el desarrollo y gestión de Proyectos de Vivienda a nivel nacional. Empieza a construir tu historia aquí.",
    metaKeyword:"Biabo, Inmobiliaria,Depas, Departamentos, vivienda, bono verde, dormitorios, Avantte, Skyna, Garden, Chorrillos, Santa Beatriz, Chacarilla, Surco, constructora.",
    card:[
        {
            title:"E28 JORDI PUIG",
            direccion:"Av. Federico Villareal 111, ",
            ref:"Malecón de la Marina",
            distrito:"Miraflores",
            img:require("../Assets/images/nosotros/jordi_pug.png")
        },
        {
            title:"EL PARQUE",
            direccion:"Calle San Lorenzo 221",
            ref:"",
            distrito:"Pueblo Libre",
            img:require("../Assets/images/nosotros/el-parque.png")
        },
        {
            title:"PRIMAVERA",
            direccion:"Jr. Cristóbal de Peralta Sur 111",
            ref:"Valle Hermoso",
            distrito:"Surco",
            img:require("../Assets/images/nosotros/primavera.png")
        },
        {
            title:"SUMO",
            direccion:"Av. La Marina Esquina Calle San Marcos",
            ref:"",
            distrito:"Pueblo Libre",
            img:require("../Assets/images/nosotros/sumo.png")
        },
    ]
}
export default about