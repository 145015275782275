import React, { Component } from 'react'
import "./footer.scss"
export default class Footer extends Component {
    render() {
        const biabo = require("../../Assets/images/iconos/biabo-footer.png");
        const facebook = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.03 23.89"><defs><style>.cls-1{fill:#fff;}</style></defs><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" d="M24,12A12,12,0,1,0,10.14,23.89v-8.4H7.09V12h3.05V9.37c0-3,1.79-4.68,4.54-4.68a18.17,18.17,0,0,1,2.69.24v3H15.85a1.74,1.74,0,0,0-2,1.87V12h3.34l-.54,3.47h-2.8v8.4A12,12,0,0,0,24,12Z"/></g></g></svg>'
        const instagram = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.89 23.89"><defs><style>.cls-1{fill:#fff;}</style></defs><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" d="M11.94,2.15c3.19,0,3.57,0,4.83.07A6.67,6.67,0,0,1,19,2.63a3.8,3.8,0,0,1,1.37.9,3.5,3.5,0,0,1,.89,1.37,6.54,6.54,0,0,1,.42,2.22c0,1.26.06,1.63.06,4.82s0,3.57-.06,4.83A6.6,6.6,0,0,1,21.25,19,3.93,3.93,0,0,1,19,21.25a6.6,6.6,0,0,1-2.22.42c-1.26,0-1.64.06-4.83.06s-3.56,0-4.82-.06a6.54,6.54,0,0,1-2.22-.42,3.5,3.5,0,0,1-1.37-.89A3.8,3.8,0,0,1,2.63,19a6.67,6.67,0,0,1-.41-2.22c-.06-1.26-.07-1.64-.07-4.83s0-3.56.07-4.82A6.61,6.61,0,0,1,2.63,4.9a3.64,3.64,0,0,1,.9-1.37,3.64,3.64,0,0,1,1.37-.9,6.61,6.61,0,0,1,2.22-.41c1.26-.06,1.63-.07,4.82-.07m0-2.15C8.7,0,8.29,0,7,.07a8.7,8.7,0,0,0-2.9.56A6,6,0,0,0,.63,4.12,8.7,8.7,0,0,0,.07,7C0,8.29,0,8.7,0,11.94s0,3.65.07,4.93a8.7,8.7,0,0,0,.56,2.9A5.81,5.81,0,0,0,2,21.88a5.87,5.87,0,0,0,2.12,1.38,8.72,8.72,0,0,0,2.9.55c1.27.06,1.68.08,4.92.08s3.65,0,4.93-.08a8.72,8.72,0,0,0,2.9-.55,6.16,6.16,0,0,0,3.49-3.49,8.72,8.72,0,0,0,.55-2.9c.06-1.28.08-1.68.08-4.93s0-3.65-.08-4.92a8.72,8.72,0,0,0-.55-2.9A5.87,5.87,0,0,0,21.88,2,5.81,5.81,0,0,0,19.77.63a8.7,8.7,0,0,0-2.9-.56C15.59,0,15.19,0,11.94,0Z"/><path class="cls-1" d="M11.94,5.81a6.14,6.14,0,1,0,6.14,6.13A6.14,6.14,0,0,0,11.94,5.81Zm0,10.11a4,4,0,1,1,4-4A4,4,0,0,1,11.94,15.92Z"/><circle class="cls-1" cx="18.32" cy="5.57" r="1.43"/></g></g></svg>'
        const pinterest = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.85 23.89"><defs><style>.cls-1{fill:#fff;}</style></defs><title></title><g id="Capa_2" data-name="Capa 2"><g id="Layer_1" data-name="Layer 1"><path class="cls-1" d="M11.93,0A11.94,11.94,0,0,0,7.58,23.05a11.63,11.63,0,0,1,0-3.43L9,13.69a4.3,4.3,0,0,1-.36-1.77c0-1.66,1-2.89,2.16-2.89a1.5,1.5,0,0,1,1.52,1.68,23,23,0,0,1-1,4,1.74,1.74,0,0,0,1.77,2.16c2.12,0,3.75-2.23,3.75-5.46a4.71,4.71,0,0,0-5-4.85,5.17,5.17,0,0,0-5.39,5.18,4.67,4.67,0,0,0,.89,2.72.36.36,0,0,1,.07.34c-.08.38-.29,1.19-.33,1.36s-.18.26-.4.15c-1.49-.69-2.43-2.86-2.43-4.62C4.28,7.9,7,4.44,12.17,4.44c4.13,0,7.36,2.95,7.36,6.89,0,4.12-2.6,7.43-6.19,7.43a3.16,3.16,0,0,1-2.73-1.38l-.75,2.84a13,13,0,0,1-1.48,3.14,12.11,12.11,0,0,0,3.53.53A11.95,11.95,0,0,0,11.93,0Z"/></g></g></svg>'

        return (
            <div className={"footer "+this.props.page}>
                <div className="column">
                    <div className="brands">
                        <div className="brand"><img className='' src={biabo}></img></div>
                        <div className="brand1">
                            <span class="white paragraph-brand">
                                 ©2020 Biabo Inmobiliaria. Todos los derechos reservados. Desarrollado con ♥ por <a target='_blank' href='https://www.formulaperu.com/' className='white'>Fórmula</a>

                            </span>
                        </div>
                    </div>
                    <div className="footer-column item1 flex-row">
                        <h5>Llámanos   </h5>
                        <div className="flex-column">
                            <a target="blank" href="tel:939 192 765" className="words">939 192 765 | Proyecto Avantte</a>
                            <a target="blank" href="tel:943 918 514" className="words">943 918 514 | Proyecto Skyna</a>
                            <a target="blank" href="tel:964 193 102" className="words">964 193 102 | Proyecto Garden</a>
                            {/* <a target="blank" href="tel:" className="words">(01) 333 4444 | Proyecto Miraflores</a> */}
                        </div>
                    </div>
                    <div className="footer-column item2 flex-row">
                        <h5>Síguenos en: 
                            <a className="link"><a target="__blank" href="https://www.facebook.com/Biabo.inmobiliaria" className="icono facebook d-block" dangerouslySetInnerHTML={{__html:facebook}}></a></a>
                            <a className="link"><a target="__blank" href="https://www.instagram.com/biabo.inmobiliaria/" className="icono facebook d-block" dangerouslySetInnerHTML={{__html:instagram}}></a></a>
                        </h5>
                    </div>
                </div>
                    <div className="movil-footer flex-column">
                            <div className='link-brand flex-row'>
                                <img className='brand' alt="marca de biabo" src={require('../../Assets/images/navbar/biabo-brand.png')}></img>
                                
                            </div>
                            <div className='link-brand flex-row'>
                                <div className="information flex-column">
                                    <span className="title-direccion">Síguenos en:</span>
                                    <div className='flex-row'>
                                        <a target="_blank" href="https://www.instagram.com/biabo.inmobiliaria/" className='link-icon' dangerouslySetInnerHTML={{__html:instagram}}></a>
                                        <a target="_blank" href="https://www.facebook.com/Biabo.inmobiliaria" className='link-icon' dangerouslySetInnerHTML={{__html:facebook}}></a>
                                    </div>
                                </div>
                                <div className="information cel flex-column">
                                    <span className="title-phone">Teléfono</span>
                                    <a target="blank" href="tel:939 192 765" class="words">939 192 765 | Proyecto Avantte</a>
                                    <a target="blank" href="tel:943 918 514 " class="words">943 918 514  | Proyecto Skyna</a>
                                    <a target="blank" href="tel:964 193 102" class="words">964 193 102 | Proyecto Garden</a>                                    
                                </div>
                            </div>
                            <div className='link-brand flex-row copyright'>
                                ©2020 Biabo Inmobiliaria. Todos los derechos reservados. Desarrollado con ♥ por <a target="__blank" href='https://www.formulaperu.com/' className='white'></a>Fórmula
                            </div>
                    </div>
            </div>
        )
    }
}
